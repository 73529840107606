import React, { useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  Accordion,
  Badge,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { usePhases } from "contexts/PhasesProvider";
import "../CostingTable.scss";

import { put, post } from "utils/DeApi";

import {
  cellStyle,
  costingTypesMap,
  AddGridColumn,
  costingAPIResourceMap,
  costingAPIResourcePropMap,
  hanldeCellToCellNavigation,
  updatePhasesData,
} from "./shared/utils";
import { useCostingHeaders } from "hooks/costing/useCostingGridHeaders";
import { useRefreshPhasesData } from "helpers/PhasesHelpers";
import { usePhasesDispatch } from "contexts/PhasesProvider";
import { destroy } from "utils/DeApi";

import PhaseTitle from "./shared/PhaseTitle";
import PhaseTotals from "./shared/PhaseTotals";
import CostingDataCell from "./shared/EditableCostingDataCell";

import { newCostingResourceMap } from "./shared/newCostingData";
import { useSubContractorData } from "./hooks";
import useOrderCostingData from "hooks/costing/useOrderCostingData";

const hoursStyle = [
  "bg-warning text-danger",
  "bg-primary text-primary",
  "bg-success text-success",
];

function SubContractorEditableGrid({ refreshOpportunityCostingSummary }) {
  const [searchParams] = useSearchParams();
  const costingType = searchParams.get("type");

  const { opportunityId } = useParams();

  const phasesDispatch = usePhasesDispatch();
  const phasesData = usePhases();

  const [data, setData] = useSubContractorData(phasesData);
  const orderedData = useOrderCostingData(
    opportunityId,
    data,
    "subContractors",
  );

  console.debug("OrderedGridData", orderedData);

  const cellRefs = useRef(new Map()).current;

  const { callGetPhasesAPI } = useRefreshPhasesData();

  const rowMutation = useMutation({
    mutationFn: ({ row, col, payload, isUpdate }) => {
      return isUpdate
        ? put(
            `/activities/${col.id}/${costingAPIResourceMap[costingType]}/${row.id}`,
            { ...payload },
          ).promise
        : post(`/activities/${col.id}/${costingAPIResourceMap[costingType]}`, {
            ...payload,
          }).promise;
    },
    onSuccess: (data, variables, context) => {
      updatePhasesData({
        oldPhasesData: phasesData,
        newPhasesData: data.data,
        costingType,
        phasesDispatch,
      });
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const rowMutationUpdateSubContractor = useMutation({
    mutationFn: ({ row, payload }) => {
      return put(
        `/opportunities/${opportunityId}/activity_subcontractors/${row.id}`,
        { ...payload },
      ).promise;
    },
    onSuccess: (data, variables, context) => {
      updatePhasesData({
        oldPhasesData: phasesData,
        newPhasesData: data.data,
        costingType,
        phasesDispatch,
      });
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const rowDelete = useMutation({
    mutationFn: ({ row }) => {
      return destroy(
        `/opportunities/${opportunityId}/activity_subcontractors/${row.id}`,
      ).promise;
    },
    onSuccess: (data, variables, context) => {
      callGetPhasesAPI();
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const columns = useCostingHeaders(costingType)
    .concat(
      phasesData.map((phase, phaseIndex) => ({
        id: phase.id,
        header: () => (
          <PhaseTitle
            eventKey={phase.id}
            phaseIndex={phaseIndex}
            title={phase.title}
          />
        ),
        columns: phase.activities.map((activity, activityIndex) => ({
          id: activity.id,
          header: `${phaseIndex + 1}.${activityIndex + 1} ${activity.title}`,
          footer: (props) => props.column.id,
          accessorFn: (row) =>
            row.phases?.[phaseIndex]?.activities[activityIndex].totalHours || 0,
          cell: CostingDataCell,
          meta: {
            celInputProps: {
              type: "number",
              apiFieldKey: "qty",
            },
            activity: activity,
          },
        })),
        accessorFn: (row) => row.phases[phaseIndex].totalHours,
        footer: (props) => props.column.id,
      })),
    )
    .concat([
      {
        id: "contingency_label",
        header: () => {
          return (
            <div className="border sticky-left cell-heading-width align-middle fw-bold fs-5 px-2 py-1 border-0">
              Contingency
            </div>
          );
        },
      },
    ])
    .concat(
      phasesData.map((phase, phaseIndex) => ({
        id: phase.id + "_" + phaseIndex,
        header: () => (
          <PhaseTitle
            eventKey={phase.id + "_" + phaseIndex}
            phaseIndex={phaseIndex}
            title={phase.title}
          />
        ),
        columns: phase.activities.map((activity, activityIndex) => ({
          id: activity.id + "_" + activityIndex + phaseIndex,
          header: `${phaseIndex + 1}.${activityIndex + 1} ${activity.title}`,
          footer: (props) => activity.id,
          accessorFn: (row) =>
            row.phases?.[phaseIndex]?.activities[activityIndex]
              .activityContingency || 0,
          cell: CostingDataCell,
          meta: {
            celInputProps: {
              type: "number",
              apiFieldKey: "activityContingency",
            },
            activity: activity,
          },
        })),
        accessorFn: (row) => row.phases[phaseIndex].activityContingency,
        footer: (props) => phase.id,
      })),
    );

  const table = useReactTable({
    data: orderedData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      name: "SubContractor Grid",
      key: "subContractorGrid",
      updateData: (
        rowId,
        columnId,
        key,
        value,
        updatedRow,
        isLabourForActivity,
        isSaveToDB = true,
        isResourceProperty = false,
        isSubContractorUpdate = false,
      ) => {
        if (key === "activityContingency") {
          columnId = columnId?.split("_")[0];
          updatedRow?.phases?.forEach((phase) => {
            phase?.activities?.forEach((activity) => {
              if (activity.id === columnId) {
                updatedRow["qty"] = activity?.totalHours;
              }
            });
          });
        } else if (key === "qty") {
          updatedRow?.phases?.forEach((phase) => {
            phase?.activities?.forEach((activity) => {
              if (activity.id === columnId) {
                updatedRow["activityContingency"] =
                  activity?.activityContingency;
              }
            });
          });
        }
        setData((old) =>
          old.map((row) => {
            if (row.id === rowId) {
              if (isSaveToDB) {
                if (isResourceProperty) {
                  if (updatedRow._id?.includes("new")) {
                    updatedRow.name = updatedRow.id;

                    rowMutation.mutate({
                      row: updatedRow,
                      col: {
                        id: phasesData[0]?.activities[0]?.id,
                      },
                      payload: {
                        ...updatedRow,
                        totalHours:
                          updatedRow.phases[0]?.activities[0]?.totalHours || 0,
                      },
                      isUpdate: phasesData.some((ph) => {
                        return ph.activities.some((activity) =>
                          activity[costingAPIResourcePropMap[costingType]].find(
                            (lb) =>
                              lb.id === updatedRow.id &&
                              !lb._id?.includes("new"),
                          ),
                        );
                      }),
                    });
                  } else {
                    if (isSubContractorUpdate) {
                      updatedRow.name = updatedRow.id;
                      updatedRow.orderIndex = row.order;
                    }
                    rowMutationUpdateSubContractor.mutateAsync({
                      row: row,
                      payload: {
                        localCost: updatedRow.localCost,
                        [isSubContractorUpdate ? "newSubcontractorName" : key]:
                          value,
                      },
                    });
                  }
                } else {
                  let rowId;
                  updatedRow.phases.forEach((ph) =>
                    ph.activities.forEach((act) => {
                      if (act.id === columnId) {
                        rowId = act.activitySubContractorId;
                      }
                    }),
                  );

                  const mutatedRow = {
                    ...updatedRow,
                    id: rowId || updatedRow.id,
                  };

                  rowMutation.mutateAsync({
                    row: mutatedRow,
                    col: {
                      id: columnId,
                    },
                    payload: {
                      ...mutatedRow,
                      [key]: value,
                    },
                    isUpdate: updatedRow.phases.find((ph) =>
                      ph.activities.find((act) => act.id === columnId)
                        ?.activitySubContractorId
                        ? true
                        : false,
                    ),
                  });
                }
              }
              return updatedRow;
            }
            return row;
          }),
        );
      },
      cellRefs,
      handleDeleteRow: (rowId) => {
        if (rowId?.includes("new")) {
          setData((old) => old.filter((row) => row.id !== rowId));
          const _phases = [...phasesData];
          _phases[0]?.activities[0]?.[
            costingAPIResourcePropMap[costingType]
          ]?.splice(
            _phases[0]?.activities[0]?.[
              costingAPIResourcePropMap[costingType]
            ]?.findIndex((lb) => lb.id === rowId),
            1,
          );
          phasesDispatch(_phases);
          return;
        }
        rowDelete.mutate({
          row: {
            id: rowId,
          },
        });
      },
      handleKeyDown: hanldeCellToCellNavigation,
      handleAddNewRow: (orderIndex) => {
        const newCostingResourceCopy = Object.assign(
          {},
          newCostingResourceMap[costingType],
        );
        newCostingResourceCopy.orderIndex = orderIndex;
        newCostingResourceCopy.id = `new-${new Date().getTime()}`;
        newCostingResourceCopy._id = newCostingResourceCopy.id;
        const _phases = [...phasesData];
        _phases[0]?.activities[0]?.[
          costingAPIResourcePropMap[costingType]
        ]?.push(newCostingResourceCopy);
        phasesDispatch(_phases);
      },
    },
  });

  return (
    <Table responsive id="costing-grid">
      <div className="p-0 position-relative">
        {table.getAllColumns().map((col) => {
          const colHeader = table
            .getFlatHeaders()
            .find((header) => header.column.id === col.id);
          const isPhaseHeader = !!phasesData.find((p) => p.id === col.id);
          const isContigencyHeader = !!phasesData.find(
            (p, i) => p.id + "_" + i === col.id,
          );
          if (isPhaseHeader || isContigencyHeader) {
            return (
              <Accordion flush key={col.id} defaultActiveKey={col.id}>
                <tr>
                  {flexRender(col.columnDef.header, colHeader.getContext())}
                  {table.getRowModel().rows.map((row) => {
                    const colCellValue = row.getValue(col.id);
                    return (
                      <React.Fragment key={row.id}>
                        <PhaseTotals eventKey={col.id} row={row} col={col}>
                          <span className="d-flex justify-content-end align-middle my-auto">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Phase total{" "}
                                  {isContigencyHeader
                                    ? "contingencies"
                                    : "hours"}
                                </Tooltip>
                              }
                            >
                              <Badge
                                className={
                                  [
                                    hoursStyle[
                                      !colCellValue
                                        ? 0
                                        : colCellValue <= 25
                                        ? 1
                                        : 2
                                    ],
                                  ] + " rounded-0 py-1 px-2 bg-opacity-25"
                                }
                              >
                                <h6 className="mb-0">{colCellValue || 0}</h6>
                              </Badge>
                            </OverlayTrigger>
                          </span>
                        </PhaseTotals>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <Accordion.Collapse className="p-0" eventKey={col.id}>
                  <>
                    {col.columns.length === 0 && (
                      <tr>
                        <Alert
                          variant="info"
                          className={`my-3 d-flex flex-row`}
                        >
                          <div className="me-3">
                            <span className="material-icons md-18 text-primary text-opacity-75">
                              info
                            </span>
                          </div>
                          <div>
                            <h5 className="mb-1">
                              <small>No activities in this phase</small>
                            </h5>
                            <p className="mb-1">
                              <small>
                                There are currently no activities in this phase,
                                please go to the{" "}
                                <Link
                                  to={`/pricing/opportunities/${opportunityId}/wbs`}
                                >
                                  <strong>WBS</strong>
                                </Link>{" "}
                                to add activities
                              </small>
                            </p>
                          </div>
                        </Alert>
                      </tr>
                    )}
                    {col.columns.map((subCol) => (
                      <tr key={`${subCol.id}`}>
                        <td
                          className={
                            "border bg-white cell-heading-width align-middle cell-padding"
                          }
                        >
                          {subCol.columnDef.header}
                        </td>
                        {table.getRowModel().rows.map((row) => {
                          const colCell = row
                            .getVisibleCells()
                            .find((cell) => cell.column.id === subCol.id);
                          return (
                            <React.Fragment key={row.id}>
                              {colCell ? (
                                flexRender(
                                  colCell.column.columnDef.cell,
                                  colCell.getContext(),
                                )
                              ) : (
                                <td
                                  className={cellStyle}
                                  ref={(el) => cellRefs.set(colCell.id, el)}
                                  onKeyDown={(e) =>
                                    hanldeCellToCellNavigation(
                                      e,
                                      row,
                                      colCell.column,
                                      table,
                                    )
                                  }
                                ></td>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    ))}
                  </>
                </Accordion.Collapse>
              </Accordion>
            );
          }
          if (col?.columnDef?.id === "contingency_label") {
            return (
              <div key={col.id}>
                <hr
                  className="m-0 mt-3 mb-2 p-0"
                  style={{
                    height: "2px",
                    border: "none",
                    color: "#333",
                    backgroundColor: "#333",
                  }}
                />
                {flexRender(col.columnDef.header, colHeader.getContext())}
              </div>
            );
          }
          return (
            <tr key={col.id}>
              {flexRender(col.columnDef.header, colHeader.getContext())}
              {table.getRowModel().rows.map((row) => {
                const colCell = row
                  .getVisibleCells()
                  .find((cell) => cell.column.id === col.id);

                return (
                  <React.Fragment key={row.id}>
                    {colCell ? (
                      flexRender(
                        colCell.column.columnDef.cell,
                        colCell.getContext(),
                      )
                    ) : (
                      <td className={cellStyle}></td>
                    )}
                  </React.Fragment>
                );
              })}
              {col.id === "colTag" && (
                <AddGridColumn
                  handleClick={() => {
                    table.options.meta?.handleAddNewRow(data.length);
                  }}
                  costingType={costingTypesMap[costingType]}
                  message={
                    phasesData?.reduce(
                      (acc, curr) => acc + curr?.activities?.length,
                      0,
                    )
                      ? ""
                      : "(No Activity Available)"
                  }
                />
              )}
            </tr>
          );
        })}
      </div>
    </Table>
  );
}

export default React.memo(SubContractorEditableGrid);

SubContractorEditableGrid.propTypes = {
  refreshOpportunityCostingSummary: PropTypes.func.isRequired,
};
