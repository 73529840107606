import React, { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import {
  handleOnInput,
  getUnSavedRowStyles,
  cellStyle,
  costingAPIResourcePropMap,
} from "components/CostingGrid/EditableGrid/shared/utils";
import * as yup from "yup";
import { invalidInputStyles } from "./utils";

const CostingDataCell = ({ getValue, row, column, table, cell }) => {
  const cellInputProps = column.columnDef.meta?.celInputProps;
  const activity = column.columnDef.meta?.activity;
  const isChildColumn = cellInputProps?.isChildColumn === true ? true : false;

  const [searchParams] = useSearchParams();
  const costingType = searchParams.get("type");

  const [error, setError] = useState("");
  const initialValue = getValue();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(
    !!initialValue && initialValue !== 0 ? "" : initialValue,
  );

  const getColumnId = (column) => {
    if (isChildColumn && column.parent) {
      return column.parent.id;
    }
    return column.id;
  };

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    // restore 0 value if input is empty, all input cells are numbers
    let valueToSave = value;
    if (value === "") {
      valueToSave = 0;
      setValue(valueToSave);
    }
    if (error) {
      return;
    }

    const updatedRow = {
      ...row.original,
      [cellInputProps?.apiFieldKey || getColumnId(column)]: valueToSave,
    };
    table.options.meta?.updateData(
      row.original.id,
      getColumnId(column),
      cellInputProps?.apiFieldKey || getColumnId(column),
      valueToSave,
      updatedRow,
      !!activity?.[costingAPIResourcePropMap[costingType]].find(
        (rsc) => rsc.id === row.original.id,
      ),
      initialValue !== valueToSave,
    );
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // all input cells here are numbers, so we need to make sure that the value is a number
  // and not a string
  // we cannot use input type="number" because we need to be able to be able to locate cursor position
  // when navigating with arrow keys, and input type="number" does not allow that

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const errorMessage = "Value must be a positive number";
    //a user is able to have an empty field
    if (inputValue === "") {
      setValue("");
      setError("");
      return;
    }
    const schema = yup.object().shape({
      //positive() excludes 0 from being a valid number
      value: yup.number().min(0, errorMessage).max(1000000),
    });

    schema
      .validate({ value: inputValue })
      .then(() => {
        setValue(inputValue);
        setError("");
      })
      .catch((_) => {
        setError(errorMessage);
        setValue(inputValue);
      });
  };
  return (
    <td
      className={
        cellStyle +
        (cellInputProps?.type === "number" ? " text-end pe-1" : "") +
        getUnSavedRowStyles(row, column)
      }
    >
      <OverlayTrigger
        overlay={
          cellInputProps?.disabled ? (
            <Tooltip>Read only</Tooltip>
          ) : (
            <Tooltip>{error || "Editable Cell"}</Tooltip>
          )
        }
        trigger={["hover", "focus"]}
        show={!!error}
      >
        {cellInputProps?.disabled ? (
          <span className={"border-0"}>{value}</span>
        ) : (
          <input
            ref={(el) => table.options.meta?.cellRefs.set(cell.id, el)}
            type={"text"}
            disabled={cellInputProps?.disabled}
            className={`${
              cellInputProps?.type === "number" ? "text-end pe-1" : ""
            } ${error ? "border" : "border-0"} h-100 w-100 ${invalidInputStyles(
              error,
            )}`}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              table.options.meta?.handleKeyDown(e, row, column, table)
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") e.target.blur();
            }}
            onInput={handleOnInput}
          />
        )}
      </OverlayTrigger>
    </td>
  );
};

export default CostingDataCell;
